import React, { useState, useEffect } from "react";
import axios from "axios";
import { Search, FileText } from "lucide-react";

function Circular() {
    const [search, setSearch] = useState("");
    const [circulars, setCirculars] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get("https://minf-backend.vercel.app/circulars")
            .then(response => {
                setCirculars(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching circulars:", error);
                setLoading(false);
            });
    }, []);

    const filteredCirculars = circulars.filter((circular) =>
        circular.department.toLowerCase().includes(search.toLowerCase()) ||
        circular.title.toLowerCase().includes(search.toLowerCase())
    );

    const image = "url('https://minf-minf.s3.ap-south-1.amazonaws.com/814f97f5-951e-4178-aefb-69bea61ad93e.jpg')";

    return (
        <section className="bg-transparent mb-12 relative">
            <div
                className="mx-5 mb-12 rounded-lg "
                style={{ backgroundImage: image, backgroundSize: "cover" }}
            >
                <div className="flex md:h-[40vh] h-[50vh] justify-center rounded-lg items-center bg-[rgba(0,0,0,0.7)]">
                    <p className="px-8 md:text-8xl text-5xl text-center text-white tracking-widest mt-[80px] interf pb-2">
                        CIRCULARS
                    </p>
                </div>
            </div>
            <div className="max-w-7xl mx-auto md:px-0 px-8 mb-8">
                <div className="relative flex">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                        type="text"
                        placeholder="Search by Title or Department"
                        className="w-full pl-12 pr-4 py-3 rounded-lg border-2 border-gray-700 bg-transparent focus:border-gray-700 focus:ring-1 focus:ring-gray-700 transition-all outline-none"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <div className='max-w-7xl overflow-scroll border-2 mx-8 border-gray-700 rounded-xl md:mx-auto relative'>
                {loading ? (
                    <div className="text-center py-6 text-gray-500">Loading...</div>
                ) : (
                    <table className="w-full border-collapse">
                        <thead>
                            <tr className="border-b-gray-700 border-2">
                                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-900 border-b">S.No</th>
                                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-600 border-b">Department</th>
                                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-600 border-b">Title</th>
                                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-600 border-b">Date</th>
                                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-600 border-b">Attachment</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-700 overflow-y-scroll">
                            {filteredCirculars.length === 0 ? (
                                <tr>
                                    <td className="px-6 py-4 text-sm text-center text-gray-600" colSpan={5}>
                                        No Data Available
                                    </td>
                                </tr>
                            ) : (
                                filteredCirculars.map((circular, index) => (
                                    <tr key={circular.id} className="hover:bg-gray-500/20 cursor-pointer transition-colors duration-200">
                                        <td className="px-6 py-4 text-sm text-gray-600">{index + 1}</td>
                                        <td className="px-6 py-4 text-sm font-medium">{circular.department}</td>
                                        <td className="px-6 py-4 text-sm font-medium text-gray-800">{circular.title}</td>
                                        <td className="px-6 py-4 text-sm text-gray-600">
                                            {new Date(circular.date).toLocaleDateString('en-US', {
                                                year: 'numeric', month: 'short', day: 'numeric'
                                            })}
                                        </td>
                                        <td className="px-6 py-4">
                                            <a href={circular.attachment}>
                                                <button className="inline-flex items-center gap-2 text-gray-600 hover:text-red-500 text-sm font-medium transition-colors duration-200">
                                                    <FileText className="h-4 w-4" />
                                                    View
                                                </button>
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="text-xl flex justify-center mt-5 text-gray-500">
                {filteredCirculars.length} {filteredCirculars.length === 1 ? 'Circular' : 'Circulars'}
            </div>
        </section>
    );
}

export default Circular;