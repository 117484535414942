import React from 'react';
import { MapPin, Mail, Phone } from 'lucide-react';

function StateCard({ state }) {

    if (!state) return (
        <div className="flex h-[200px] border-2 border-gray-800 rounded-2xl overflow-hidden cursor-pointer">
            {/* Skeleton Loader */}
            <div className="w-1/2 relative group animate-pulse">
                <div className="absolute inset-0 bg-gray-300" />
            </div>
            <div className="w-1/2 py-2 pl-3 pr-2 flex flex-col">
                <div className='flex justify-between items-center'>
                    <div className='h-4 w-16 bg-gray-300 rounded'></div>
                    <div className='h-8 w-24 bg-gray-300 rounded'></div>
                </div>
                <div className='flex justify-between my-3 items-center '>
                    <div>
                        <div className='h-8 w-32 bg-gray-300 rounded mb-2'></div>
                        <div className='h-6 w-24 bg-gray-300 rounded'></div>
                        <div className='flex text-sm mt-2 items-center gap-1'>
                            <div className='h-4 w-16 bg-gray-300 rounded'></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='w-full flex text-md gap-2 rounded-xl mx-auto justify-between'>
                        <div className='border-2 w-full border-gray-800 font-bold h-[40px] rounded-lg flex justify-center items-center'>
                            <div className='h-4 w-16 bg-gray-300 rounded'></div>
                        </div>
                        <div className='border-2 w-full border-gray-800 font-bold h-[40px] rounded-lg flex justify-center items-center'>
                            <div className='h-4 w-16 bg-gray-300 rounded'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="flex h-[200px] border-2 border-gray-800 rounded-2xl overflow-hidden cursor-pointer">
            {/* Left side - Image */}
            <div className="w-1/2 relative group">
                <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <img
                    src={state.photo}
                    alt="Profile"
                    className="w-full h-full object-cover object-top "
                />
            </div>

            {/* Right side - Content */}
            <div className="w-1/2 py-2 pl-3 pr-2 flex flex-col">
                {/* top rank */}
                <div className='flex justify-between items-center'>
                    <p className='text-xs'>
                        {state.rank}
                    </p>
                    <p className='text-md bg-white/40 px-3 rounded-full'>{state.sId}</p>
                </div>

                {/* middle sec */}
                <div className='flex justify-between my-3 items-center '>
                    <div>
                        <p className='text-3xl font-bold'>
                            {state.name.split(' ')[0]}
                        </p>
                        <p className='text-xl font-bold'>
                            {state.name.split(' ')[1]}
                        </p>
                        <p className='flex text-sm mt-2 items-center gap-1'>
                            <MapPin size={12} /> {state.state}
                        </p>
                    </div>
                </div>

                {/* button section */}
                <div>
                    <div className=' w-full flex text-md  gap-2 rounded-xl mx-auto justify-between'>
                        <div className='border-2 w-full hover:bg-gray-800 hover:text-white border-gray-800 font-bold h-[40px] rounded-lg flex justify-center items-center'>
                            <div className='flex items-center gap-1'>
                                <a href={`mailto:${state.mail}`} target='_blank' rel='noopener noreferrer' className='flex items-center gap-1'><Mail size={16} /> <span className='text-sm'>Mail</span></a>
                            </div>
                        </div>
                        <div className='border-2 w-full hover:bg-gray-800 hover:text-white border-gray-800 font-bold h-[40px] rounded-lg flex justify-center items-center'>
                            <div className='flex items-center gap-1'>
                                <a href={`tel:${state.phone}`} target='_blank' rel='noopener noreferrer' className='flex items-center gap-1'><Phone size={16} /> <span className='text-sm'>Phone</span></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default StateCard;

