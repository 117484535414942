import React, { useState } from 'react'
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import { ButtonLeft } from './Button';
import { TrainRounded } from "@mui/icons-material";
import axios from 'axios';




function StateCardUpload({ uploadToS3 }) {
  const [singleFile, setSingleFile] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const initialState = {
    name: "",
    state: "",
    rank: "",
    mail: "",
    phone: "",
    photo: "",
  }
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSingleFileUpload = async (event) => {
    event.preventDefault()
    setLoading(TrainRounded)
    if (!singleFile) {
      console.error("No file selected");
      return;
    }

    const link = await uploadToS3(singleFile);
    const distributionLink = link.replace("https://minf-uploads.s3.ap-south-1.amazonaws.com/", "https://d13vfm13fhaqg1.cloudfront.net/");
    formData.photo = distributionLink;
    console.log("Single file uploaded successfully. Link:", distributionLink);
    if (distributionLink) {
      setLoading(false)
    }
  };
  const handleFileChange = (e) => {
    e.preventDefault()
    setSingleFile(e.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when submitting
    try {
      await axios.post(
        `https://minf-backend.vercel.app/statecard`,
        formData
      );
      setFormData(initialState)
      setMessage("done");
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error occurred during upload.");
    } finally {
      setLoading(false); // Set loading to false after response
    }
  };

  return (
    <>

      {loading && ( // Render loading popup if loading is true
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-90 z-50">
          <div class="loader">
            <li class="ball"></li>
            <li class="ball"></li>
            <li class="ball"></li>
          </div>
        </div>
      )}

      <label htmlFor="name" className="interf text-xl text-gray-600">Name:</label><br />
      <input className="bg-transparent border-2 w-full mt-1 mb-4 border-gray-500 p-1 outline-none" type="text" name="name" id="name" value={formData.name} onChange={handleChange} />

      <label htmlFor="state" className="interf text-xl text-gray-600">State:</label><br />
      <input className="bg-transparent border-2 w-full mt-1 mb-4 border-gray-500 p-1 outline-none" type="text" name="state" id="state" value={formData.state} onChange={handleChange} />

      <label htmlFor="staterank" className="interf text-xl text-gray-600">State Rank:</label><br />
      <input className="bg-transparent border-2 w-full mt-1 mb-4 border-gray-500 p-1 outline-none" type="text" name="rank" id="staterank" value={formData.staterank} onChange={handleChange} />

      <label htmlFor="email" className="interf text-xl text-gray-600">Email:</label><br />
      <input className="bg-transparent border-2 w-full mt-1 mb-4 border-gray-500 p-1 outline-none" type="email" name="mail" id="email" value={formData.email} onChange={handleChange} />

      <label htmlFor="phone" className="interf text-xl text-gray-600">Phone:</label><br />
      <input className="bg-transparent border-2 w-full mt-1 mb-4 border-gray-500 p-1 outline-none" type="tel" name="phone" id="phone" value={formData.phone} onChange={handleChange} />

      <label htmlFor="photo" className="cursor-pointer interf text-xl text-gray-600 w-full text-center py-8 border-gray-500 border-2 block">Photo <UploadRoundedIcon fontSize="large" color="action" />
        <input className="hidden bg-transparent border-2 rounded-md w-full mt-1 mb-4 border-gray-500 p-1 outline-none" type="file" name="photo" id="photo" accept="image/*" onChange={handleFileChange} />
      </label>
      <div className="flex justify-center mt-2">
        <button onClick={handleSingleFileUpload} className="protest w-[80%] text-md h-[50px] mx-auto">
          <ButtonLeft prop={"Upload Photo"} />
        </button>
      </div>
      <div className="flex justify-center mt-2">
        <button onClick={handleSubmit} className="protest w-[80%] text-md h-[50px] mx-auto">
          <ButtonLeft prop={"Upload"} />
        </button>
      </div>

      <div className={`h-[100vh] ${message ? "flex" : "hidden"} w-[100%] mx-auto bg-[rgba(0,0,0,0.7)] fixed px-5 md:px-0 top-0 left-0 z-50 justify-center items-center`}>
        <div className="bg-[#E9EAEC] h-[50vh] flex text-[#183557] text-xl flex-col justify-center items-center gap-5 text-center interf w-full opacity-100 md:w-[50%]">
          <p>{message}</p>
          <button onClick={() => { setMessage("") }} className={`px-5 tracking-widest py-3 ${message === "done" ? "bg-green-600" : "bg-red-600"} text-white interf`}>{message === "done" ? "OKAY" : "RETRY"}</button>
        </div>
      </div>

    </>
  )
}

export default StateCardUpload