import React, { useState, useEffect, useRef } from "react";

function Nav({ isOpen }) {
  const [isSticky, setIsSticky] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [dropdata, setDropData] = useState("")
  
  const handleMouseEnter = (e) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setDropData(e)
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };

  // Add handlers for the dropdown content
  const handleDropdownMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsHovered(true);
  };

  const handleDropdownMouseLeave = () => {
    handleMouseLeave(); // Reuse the same delay logic
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Check scroll position on load
    handleScroll(); // Call the function to set initial sticky state

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drop = {
   "news" : [
    ["news", "/news"],
    ["events", "/events"]
   ]
  }


  return (
    <div>
      <div className={`${isSticky ? "h-[0px]" : "h-[16px] md:h-[32px]"} sticky z-40 w-full  `}/>
      <div
        className={`mx-auto w-[60%] md:w-[100%] container flex justify-center rounded-xl
        `}
      >
        <nav
          className={`z-40 text-md cus fixed mx-auto container md:w-[70%] rounded-b-2xl bg-[#E9EAEC] ${
            isSticky ? ` ${isHovered ? "h-[140px]" : "h-[80px]"} bg-neutral-900 cus2 w-[80%]` : "w-[48%]"
          }`}
        >
          <div className="bg-[#E9EAEC] h-[32px] w-[130%] md:w-[108%] absolute mx-auto -top-8 -left-10 "/>
          <div className={`flex flex-col ${isHovered ? `${isSticky ? "h-[140px]" : "h-[120px]"}` : "h-[16px] md:h-[60px]"} transition-[height] duration-300`}>
            <div
              className={`relative md:flex hidden mx-auto ${
                isOpen ? "w-[80%]" : "w-[90%]"
              } items-center justify-evenly ${isSticky ? "text-md" : "text-lg"}`}
              style={{
                top: isSticky ? "10px" : "-20px",
                transition: "top 0.3s linear",
                color: isSticky ? "white" : "black",
              }}
            >
              <p 
                className="cusa uppercase" 
                name="news"
                onMouseEnter={()=>handleMouseEnter("news")} 
                onMouseLeave={handleMouseLeave} 
                ref={dropdownRef}
              >
                news
              </p>
              <a href="/rankings" className="cusa">
                RANKINGS
              </a>
              <a href="/states" className="cusa">
                STATES
              </a>
              <a href="/" className="cusa selected">
                <img
                  src={isSticky ? "https://minf-minf.s3.ap-south-1.amazonaws.com/wbc-in.png" : "https://minf-minf.s3.ap-south-1.amazonaws.com/wbc.png"}
                  className={isSticky ? "h-[55px] object-cover" : "h-[65px] object-cover"}
                  alt="logo"
                />
              </a>
              <a href="/circulars" className="cusa">
                Circulars
              </a>
              <a href="/about" className="cusa">
                ABOUT
              </a>
              <a href="/contact" className="cusa">
                CONTACT
              </a>
            </div>
            <div 
              className={`rounded-b-2xl ${isHovered ? "visible" : "invisible"} ${
                isSticky ? "mt-5 h-full bg-black" : "h-full -mt-[10px] bg-black/5"
              }`}
              onMouseEnter={handleDropdownMouseEnter}
              onMouseLeave={handleDropdownMouseLeave}
            >
              <div className="h-full flex items-center gap-8 mx-auto w-[85%] text-md px-6">
                {dropdata && drop[dropdata].map((e,i)=>(<p key={i} className={` ${isSticky ? "text-white" : "text-black"} opacity-70 cursor-pointer hover:opacity-100 transition-all duration-200 uppercase`}>
                  <a href={e[1]}>
                    {e[0]}
                  </a>
                </p>))}
              </div>
            </div>
          </div>
          
          <a
            href="/"
            className={`md:hidden flex justify-center h-[45px] items-center gap-3 text-2xl ${
              isSticky ? "text-white" : "text-black"
            }`}
          >
            <img
              src={isSticky ? "https://minf-minf.s3.ap-south-1.amazonaws.com/wbc-in.png" : "https://minf-minf.s3.ap-south-1.amazonaws.com/wbc.png"}
              className={isSticky ? "object-cover h-[65px]" : "object-cover h-[45px] absolute top-0"}
              alt="logo"
            />
          </a>
        </nav>
      </div>
    </div>
  );
}

export default Nav;