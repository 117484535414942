import React from "react";
import { useState } from "react";
import Nav from "./Nav";
import { ChevronLeft } from 'lucide-react';

export default function MainNav() {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isNewsOpen, setIsNewsOpen] = useState(false);
  const [opencont, setOpencont] = useState("");

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const handleExternalClick = () => {
    // Toggle checkbox state
    setIsChecked(!isChecked);
    setIsNewsOpen(false);
    open ? setOpen(false) : setOpen(true);
  };

  function isOpen() {
    if (open) {
      setOpen(false);
      setIsNewsOpen(false); // Close news panel when main menu closes
    } else {
      setOpen(true);
    }
  }

  const handleNestedMenu = (e) => {
    e.preventDefault();
    setOpencont(e.target.textContent);
    setIsNewsOpen(!isNewsOpen);
  };

  return (
    <>
      <div className={`md:hidden fixed block top-0 z-50 h-full ${open
        ? "w-full transition-width duration-500"
        : "w-0 transition-width duration-500"} bg-transparent`} onClick={handleExternalClick}></div>
      <div
        className={`bg-neutral-900 md:hidden flex justify-center items-center h-[100vh] realative fixed z-50  ${open
          ? "w-[60%] transition-width duration-500"
          : "w-0 transition-width duration-500"
          } opacity-90 top-0 `}
      >
        <div className="h-[100px] mobafter flex justify-center items-center w-[40px] bg-neutral-900 top-1/2 rounded-r-full  absolute right-[-39px]">
          <div className="ham">
            <input type="checkbox" checked={isChecked} onClick={isOpen} onChange={handleCheckboxChange} />
            <svg>
              <use href="#menu" />
              <use href="#menu" />
            </svg>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
            <symbol
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 56"
              id="menu"
            >
              <path d="M48.33,45.6H18a14.17,14.17,0,0,1,0-28.34H78.86a17.37,17.37,0,0,1,0,34.74H42.33l-21-21.26L47.75,4" />
            </symbol>
          </svg>
        </div>
        <div
          className={`${open ? "grid" : "hidden"
            } text-white thermite text-center mt-[-70px] text-2xl gap-8`}
        >
          <p onClick={(e) => { handleNestedMenu(e) }} className="cusa">
            NEWS
          </p>
          <a href="/rankings" onClick={isOpen} className="cusa">
            RANKINGS
          </a>
          <a href="/states" onClick={isOpen} className="cusa">
            STATES
          </a>
          <a href="/circulars" onClick={isOpen} className="cusa">
            Circulars
          </a>
          <a href="/about" onClick={isOpen} className="cusa">
            ABOUT
          </a>
          <a href="/contact" onClick={isOpen} className="cusa">
            CONTACT
          </a>
        </div>
      </div>

      <div
        className={`bg-neutral-900 md:hidden flex transition-all duration-500 justify-center items-center h-[100vh] fixed z-50 ${isNewsOpen
          ? "w-[60%] "
          : "w-0 "
          } opacity-95 top-0 transition-all duration-500`}
      >

        <div
          className={`${isNewsOpen ? "grid" : "hidden"
            } text-white thermite text-center mt-[-70px] text-2xl gap-8`}
        >
          <div className="top-0 left-0 flex absolute transition-opacity duration-500"
            onClick={() => {
              setIsNewsOpen(false);
            }}>
            <p className="p-2 flex gap-5 items-center justify-center">
              <ChevronLeft size={30} /> <p className="text-2xl mb-[-10px]">{opencont}</p>
            </p>
          </div>
          <a href="/news" onClick={isOpen} className="cusa">
            NEWS
          </a>
          <a href="/events" onClick={isOpen} className="cusa">
            EVENTS
          </a>
        </div>
      </div>


      <Nav open={isOpen} />
    </>
  );
}
