import React, { useEffect, useState } from 'react'
import StateCard from '../StatesPage/StateCard'

function States() {
  const [statesData, setStatesData] = useState([]);
  const image = "url('https://minf-minf.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2024-02-16+at+7.28.32+PM+(1).jpeg')"
    
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://minf-backend.vercel.app/statecard');
        const data = await response.json();
        setStatesData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

    return (
        <section className="bg-transparent mb-12">
            <div
                className="mx-5 mb-12 rounded-lg "
                style={{ backgroundImage: image, backgroundSize: "cover", backgroundPosition: "center" }}
            >
                <div className="flex md:h-[65vh] h-[50vh] justify-center rounded-lg items-center bg-[rgba(0,0,0,0.7)]" >
                    <p className="px-8 md:text-8xl text-5xl text-center text-white tracking-widest mt-[80px] interf pb-2">
                        State Bodies
                    </p>
                </div>
            </div>

            <div className='container mx-auto px-5'>
                <div className='grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
                    {statesData.map((state, index) => (
                        <StateCard key={index} state={state} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default States